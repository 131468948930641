// アンケートフォーム

<template lang="pug">
el-card.member-survey-form

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  article(v-if='isReady')

    h1 {{ isActive ? title : `(受付終了) ${title}` }}

    //- 期間内かどうか
    template(v-if='isActive')

      p.about-survey 入力完了後「回答内容を確認」ボタンを押してください。

      survey-form(
        :items='formItems',
        v-model='answer',
        :agreement.sync='agreement',
        ref='form'
      )

      .buttons
        el-button(
          :disabled='!agreement',
          @click='showDialog',
          type='primary'
        ) 回答内容を確認

    //- 期間外で回答済み
    .confirmation(v-else-if='answer')
      p 回答内容は以下のとおりです。
      survey-answer-confirmation.answers(
        :answers='answer',
        :form-items='formItems'
      )

    //- 期間外で未回答
    p.is-not-answered(v-else) このアンケートは未回答です。

  confirm-answer-dialog(
    :visible.sync='dialogVisible',
    :answer='answer',
    :items='formItems',
    :survey-id='surveyId',
    @ok='completed'
  )
</template>

<script>
import get from 'lodash.get'

import SurveyForm from '@/components/survey-form'
import SurveyAnswerConfirmation from '@/components/survey-answer-confirmation'

import ConfirmAnswerDialog from '@/dialogs/confirm-answer-dialog'

import getSurveyApi from '@/api/get-survey'

export default {
  name: 'MemberSurveyForm',

  components: {
    SurveyForm,
    SurveyAnswerConfirmation,
    ConfirmAnswerDialog,
  },

  data() {
    return {
      data: null,
      isReady: false,
      answer: null,
      agreement: false,
      dialogVisible: false,
    }
  },

  computed: {
    surveyId() {
      return parseInt(this.$route.params.surveyId, 10)
    },
    title() {
      return get(this.data, 'title', '')
    },
    isActive() {
      return !!get(this.data, 'isActive', 0)
    },
    formItems() {
      return get(this.data, 'form', null)
    },
  },

  async created() {
    const response = await getSurveyApi(this.surveyId)
    if (!response.ok) return
    this.data = response.payload
    this.answer = response.payload.answer
    this.isReady = true
  },

  methods: {
    async showDialog() {
      try {
        await this.$refs.form.validate()
        this.dialogVisible = true
      } catch (e) {
        this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'error',
        })
      }
    },

    completed() {
      this.$alert('アンケートを送信しました。', '送信完了', {
        type: 'success',
      })
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="sass" scoped>
.member-survey-form

  article
    padding-bottom: 1rem

    h1
      color: $grey-dark
      font-size: 1.2rem
      border-bottom: 3px solid $color-1
      padding: 0.3rem

    p.about-survey
      margin-top: 1rem
      font-size: 0.9rem
      color: darken($color-1, 25%)

    p.is-not-answered
      margin-top: 1rem

    .confirmation
      p
        margin-top: 1rem
      .answers
        margin-top: 1rem

    .buttons
      margin-top: 4rem
</style>
